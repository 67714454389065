import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CmxHttpClient } from './http-client.service';
import { CountryCallingCode } from '../models/countryCallingCode';
import { Observable } from 'rxjs';
import { CmxHttpOptions } from '../cmx-api-v7/projects/cmx-api-v7/src/public_api';
import { LoaderConstants } from '../helpers/Loader.constants';

@Injectable()
export class CountryService {

  constructor(private cmxHttp: CmxHttpClient) {
  }

  public getConfigurationParametersByCountry(paramDescription: string, countryCode: string) {
    let uri = `v5/gm/countries?process=${paramDescription}`;
    if (countryCode) {
        uri += `&countryCode=${countryCode}`;
    }
    const options: CmxHttpOptions = LoaderConstants.SKIP_LOADER_HEADER;
    return this.cmxHttp.get(uri, options)
        .pipe(map((res) => res && res['countries'] ? res['countries'] : null));
}

  getCountryAreaCodes(): Observable<CountryCallingCode[]> {
    const uri = `v4/gm/countries?countryCallingCode=true`;
    return this.cmxHttp.get<CountryCallingCode[]>(uri).pipe(map((res) => res ? res['countries'].map(country => ({
      ...country,
      countryCode: country.countryCode.trim(),
      countryCallingCode: `+${country.countryCallingCode.trim()} `,
      fullCode: `+${country.countryCallingCode} (${country.countryCode.trim()})`
    })) : []));

  }
}
